.keysteps-main,
.keysteps-main * {
    --clr-dark-blue: #1C2253;
    --clr-light-blue: #19C1F3;
}

.bg-whole-parent {
    position: relative;
}

.keysteps-bg {
    position: absolute;
    width: 100%;
    height: 500px;
    background-image: url('./images/EllipseTrio.svg'),
    url('./images/EllipsePie270.svg'),
    url('./images/EllipseYellow.svg');
    background-size: 11vw 30vw, 20px 20px, 600px 600px;
    background-position: 5% 10%, 95% 10%, 105% 5%;
    background-repeat: no-repeat, no-repeat, no-repeat;
    z-index: -1;
}

.keysteps-main {
    font-size: 5rem;
    width: 100%;
}

.blue-title {
    color: var(--clr-dark-blue);
}

.left-content {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 2.8vw;
    line-height: 1.2;
    align-self: center;
    color: var(--clr-light-blue);
}

.keysteps-content-row {
    justify-content: center;
    align-items: center;
    margin-block: 4rem;
}

.keysteps-container-row {
    justify-content: space-around;
}

.keysteps-react-player {
    min-height: 16vw;
    object-fit: contain;
    aspect-ratio: 16/9;
    transition: min-height 100ms linear;
}

.keysteps-react-player.is-playing {
    min-height: 28vw;
}

.keysteps-react-player-col {
    position: relative;
}

.dot-matrix-bg {
    content: '';
    position: absolute;
    background-image: url('./images/dotMatrix.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 8vw;
    height: 8vw;
    bottom: -4vw;
    left: -3vw;
    z-index: -1;
}

.padd-0-columns,
.keysteps-container {
    padding-inline: 0 !important;
}

.keysteps-react-player {
    padding: 10px;
    box-shadow: 0px 8.93px 47.93px 6.48282px rgba(0, 0, 0, 0.25);
    background-color: #fff;
}

.keysteps-react-player iframe .ytp-impression-link {
    display: none;
}

.play-icon {
    transition: transform 100ms linear;
    width: 5vw;
}

.play-icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .keysteps-content-main-col {
        width: 69vw;
    }
}

@media (min-width: 575px) and (max-width: 1025px) {
    .keysteps-bg {
        background-size: 150px 300px, 20px 20px, 600px 600px;
        background-position: 12% top, 95% 10%, 105% 5%;
    }

    .play-icon {
        width: 8vw;
    }
}

@media (min-width: 575px) and (max-width: 769px) {

    .keysteps-react-player {
        min-height: 235px;
    }

    .keysteps-react-player.is-playing {
        min-height: 400px;
    }
  
    .keysteps-bg {
      height: 300px;
      background-size: 150px 300px, 20px 20px, 600px 600px;
      background-position: 8% center, 95% 10%, 105% 5%;
    }

    .keysteps-content-main-col {
        width: 80vw;
    }

}

@media (min-width: 575px) and (max-width: 775px) {
    
    .left-content {
        margin-bottom: 1rem;
    }

    .keysteps-bg {
        background-size: 193px 325px, 20px 20px, 600px 600px;
        background-position: 10% -71%, 95% 10%, 105% 5%;
    }
}
@media (max-width: 575px) {

    .keysteps-bg {
        background-image: url('./images/EllipseTrio.svg'),
        url('./images/EllipsePie270.svg');
        height: 100%;
        background-size: 133px 300px, 20px 20px;
        background-position: 124% 16%, 5% 100%;
        background-repeat: no-repeat, no-repeat;
    }

    .keysteps-main {
        position: relative;
        width: 84%;
    }

    .dot-matrix-bg {
        display: none;
    }

    .left-content {
        font-size: 6.5vw;
        line-height: 1.5;
    }

    .keysteps-content-row {
        margin-block: 15vw;
    }

    .keysteps-container-row {
        gap: 5vw;
        justify-content: center;
    }

    .keysteps-react-player {
        min-height: 45vw;
    }

    .keysteps-react-player.is-playing {
        min-height: 300px;
    }

    .play-icon {
        width: 50px;
    }
}

@media only screen and (max-width: 399.97px) {
  
    .keysteps-bg {
        background-size: 123px 300px, 20px 20px;
        background-position: 126% -5%, 5% 100%;
    }
}
