.header-d {
    margin-top: 7rem;
    width: 100%;
}
.titleText, .banner-text p{
    font-weight: 700!important;
}
@media screen and (min-width: 320px) and (max-width: 599.9px) {
    .header-d {
        margin-top: 4rem;
    }
}
.curved-arrow{
    display: none!important;
}
@media screen and (min-width: 600px) and (max-width: 600.9px) {
    .header-d {
        margin-top: 5rem;
    }
}
.banner-list{
    border: 1px solid #FFF;
    border-radius: 10px;
}
.banner-text  {
    font-family: "Poppins";
 }
@media screen and (max-width: 991px)   {
  
    .banner-icons{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        margin-top: 5px;
    }
 
    .banner-text  {
        font-size: 16px;
        font-family: "Poppins";
        font-weight: 700!important;
        padding-top: 1rem;
     }
     .banner-list{
        padding-left: 20px;
        padding-right: 20px;
        padding-top:20px;
        padding-bottom:1.5rem;
     }
     .Tru-btn{
        margin-top: -1rem;
    }
}
@media (min-width: 390px) and (max-width: 599px) {
    .banner-icons{
        width: 20px;
        height:20px;
    }
  }
@media screen and (min-width: 992px)   {
    .banner-icons{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-top: 5px;
    }
    .banner-list{
        border: 1px solid #FFF;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 7px;
        padding-bottom: 2rem;
        border-radius: 10px;
    }
    .Tru-btn{
        margin-top: -1.5rem;
    }
    .banner-text  {
        font-size: 20px!important;
        font-family: "Poppins";
        line-height: 25px;
     }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-text  {
        font-size: 24px!important;
        line-height: 30px;
     }
}
@media screen and (min-width: 1400px){
    .Tru-btn{
        margin-top: -2rem;
    }
    .banner-list{
        padding-bottom: 3rem;
    }
    .banner-text  {
        font-size: 28px!important;
        line-height: 35px;
     }
}