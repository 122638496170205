  
.ellipse-keysteps {
    position: absolute;
    background-image: url('./images/EllipseLeft.svg');
    width: 800px;
    height: 800px;
    z-index: -1;
    background-size: 800px 800px;
    background-position: -80% 0;
    background-repeat: no-repeat;
    top: max(170vw, 100vh - 800px);
    left: -10%;
}

.ellipse-nexthome-left {
    position: absolute;
    background-image: url('./images/Ellipse_NextHome_Left.svg'),
    url('./images/DotMatrixBlue.svg');
    width: 800px;
    height: 1000px;
    z-index: -1;
    background-size: 800px 1000px, 8vw 8vw;
    background-position: -80% 0, 9vw 1%;
    background-repeat: no-repeat;
    top: 80vw;
    left: -10%;
}

.ellipse-nexthome-left {
    position: absolute;
    background-image: url('./images/Ellipse_NextHome_Left.svg'),
    url('./images/DotMatrixBlue.svg');
    width: 700px;
    height: 1000px;
    z-index: -1;
    background-size: 700px 69vw, 8vw 8vw;
    background-position: center, 5vw 1%;
    background-repeat: no-repeat;
    top: 80vw;
    left: -5%;
}

.ellipse-nexthome-right {
    position: absolute;
    background-image: url('./images/Ellipse_NextHome_Right.svg');
    width: 500px;
    height: 1000px;
    z-index: -1;
    background-size: 500px 69vw;
    background-position: 100% 0;
    background-repeat: no-repeat;
    top: 80vw;
    right: 0;
}
  
.ellipse-faq {
    position: absolute;
    background-image: url('./images/EllipseFAQ.svg');
    width: 20vw;
    height: 800px;
    z-index: -1;
    background-size: 20vw 800px;
    background-position: 100% 0;
    background-repeat: no-repeat;
    top: max(198vw, 100vh - 800px);
    right: 0;
}

@media (min-width: 1650px) {
    .ellipse-nexthome-left {
        background-image: url('./images/Ellipse_NextHome_Left.svg');
        background-size: 700px 69vw;
        background-position: -80% 0;
    }
}
  
@media (min-width: 575px) and (max-width: 769px) {
    .ellipse-keysteps {
        width: 100vw;
        height: 750px;
        background-size: 750px 750px;
        background-position: -120% 0;
        top: 240vw;
        left: -10%;
    }
  
    .ellipse-faq {
        top: 260vw;
    }

    .ellipse-nexthome-left {
        width: 100vw;
    }
}
  
@media (max-width: 575px) {
  
    .ellipse-faq,
    .ellipse-nexthome-left,
    .ellipse-nexthome-right,
    .dot-matrix-bg {
        display: none;
    }

    .ellipse-keysteps {
        position: absolute;
        background-image: url('./images/EllipseRightMobile.svg');
        width: 80%;
        height: 300px;
        z-index: -1;
        background-size: 500px 500px;
        background-position: center;
        background-repeat: no-repeat;
        top: 1202vw;
        left: 20%;
        pointer-events: none;
    }
    
}
  