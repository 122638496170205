.gallery-section-container .thumbnail-container {
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
}

.gallery-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 1rem;
}

.gallery-section-container .react-multi-carousel-track {
  gap: 5px;
}

.react-player-container {
  width: 100%;
  position: relative;
}

.react-player-class-container {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .react-player-class-container {
    width: 100% !important;
    height: 50vh !important;
  }
}

.react-player-container > * {
  max-width: 90%;
  margin: 0 auto;
}

.video-grid {
  display: grid;
  justify-content: center;
  grid-auto-columns: 50px minmax(200px, 100vw) 50px;
  align-content: center;
  align-items: center;
}

.middle-section {
  display: flex;
  flex-direction: column;
}

.video-grid-item:nth-child(1) {
  grid-column: 1 / 2;
  justify-self: start;
}

.video-grid-item:nth-child(2) {
  grid-column: 3 / 4;
  justify-self: end;
}
.video-grid-item:nth-child(3) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  display: none;
}
.slider-custom-arrows {
  align-self: center;
  justify-self: center;
  border-radius: 3px;
  font-family: 'Roboto-Bold';
  background: #ebebeb;
  color: #808080;
  width: fit-content;
  padding: 1rem;
  line-height: 1;
  cursor: pointer;
}
.slider-custom-arrows:hover {
  border: 1px solid #808080;
  border-radius: 3px;
  opacity: 0.5;
}
.next-btn {
  position: absolute;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: yellow;
  min-width: 43px;
  min-height: 43px;
  right: 0;
  cursor: pointer;
}

.thumbnail-image {
  width: 100%;
  cursor: pointer;
}

.thumbnail-image:hover {
  box-shadow: 0px 0px 8px #1e4383;
}

button.gallery-view-more-btn.btn-primary {
  font-size: 18px;
  font-family: 'Roboto-Medium';
  border-top-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid #083f88 !important;
  border-left: 1px solid #083f88 !important;
  width: fit-content;
  align-self: flex-end;
  padding: 2px 10px;
  background-color: #083f88;
  color: #fff;
  text-shadow: 0 0 3px #fff;
  /* text-shadow: -1px 0px 2px #083f88; */
  /* -webkit-box-shadow: inset 0px 0px 11px 2px #083f88; 
    box-shadow: inset 0px 0px 11px 2px #083f88; */
  position: absolute;
  bottom: 4%;
}
button.gallery-view-more-btn.btn-primary:hover {
  /* -webkit-box-shadow: inset 0px 0px 11px 6px #083f88; 
    box-shadow: inset 0px 0px 11px 6px #083f88; */
  box-shadow: 0 0 4px 2px #2ba8e0;
  transition: all 0.2s linear;
}
button.gallery-view-more-btn.btn-primary:active,
button.gallery-view-more-btn.btn-primary:focus {
  outline: none;
  box-shadow: 0 0 0 1px #083f88;
}

.view-more-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #808081;
  position: absolute;
  width: 100%;
  height: 85%;
  font-family: 'Roboto-Bold';
  background-color: #eaebec;
  line-height: 1;
}

.custom-right-arrow {
  position: absolute;
  right: 0;
  bottom: -5%;
  z-index: 3;
}

.react-video-overlay {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 45vw;
  z-index: 10;
  cursor: move;
}

.react-video-overlay .react-player-class-container {
  border: 2px solid #2ba8e0;
  border-top: 0;
}

.react-video-overlay--close-btn {
  content: 'X';
  color: #fff;
  font-family: 'Roboto-Bold';
  background: transparent;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 1.5rem;
  margin-left: auto;
  line-height: 1;
  inset: -10px -10px auto auto;
  z-index: 10;
}

.react-video-overlay--close-btn:hover {
  text-shadow: 0 0 3px #0277ad;
}

#draggableHeader {
  display: flex;
  width: 100%;
  background: -moz-linear-gradient(left, #034390 0%, #fff 50%, #034390 100%);
  background: -webkit-linear-gradient(left, #034390 0%, #fff 50%, #034390 100%);
  background: linear-gradient(to right, #034390 0%, #fff 50%, #034390 100%);
  border: 2px solid #2ba8e0;
  border-bottom: 0;
  cursor: move;
}

.react-video-overlay.loader-present {
  background-color: #fff;
  opacity: 0.8;
  transition: all 0.2s linear;
}

@media (min-width: 768px) and (max-width: 1025px) {
  .react-video-overlay {
    width: 65vw;
  }
  @supports not (aspect-ratio: 16/9) {
    .react-player-class-container {
      width: 100% !important;
      height: 42vh !important;
    }
  }
}
@media (max-width: 768px) {
  .react-video-overlay {
    width: 90vw;
  }
  @supports not (aspect-ratio: 16/9) {
    .react-player-class-container {
      width: 100% !important;
      height: 42vh !important;
    }
  }
  @supports not (aspect-ratio: 2/2) {
    .react-player-class-container {
      width: 100% !important;
      height: 42vh !important;
    }
  }
}
@media (min-width: 1000px) {
  .table-title.broker-videos-title {
    font-size: 2vw;
    line-height: 1.5;
  }
}
@media (max-width: 575px) {
  .gallery-section-container .react-multiple-carousel__arrow {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 399px) {
  button.gallery-view-more-btn.btn-primary {
    font-size: 12px;
    padding: 2px 6px;
  }

  .gallery-section-container .react-multi-carousel-track {
    gap: 5px;
  }

  .video-grid {
    grid-auto-columns: 36px minmax(200px, 80vw) 36px;
  }

  .slider-custom-arrows {
    padding: 0.8rem;
  }

  .react-player-class-container {
    aspect-ratio: 2/2;
  }

  @supports not (aspect-ratio: 2/2) {
    .react-player-class-container {
      width: 100% !important;
      height: 42vh !important;
    }
  }
}
.broker-videos-title {
  color: #1c2253;
  font-style: normal;
  font-weight: 500;
}
.sm-margin {
  margin-bottom: 0px !important;
}
